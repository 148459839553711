* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  padding: 0;
  background-color: #004d71;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #808083;
}

a {
  color: #004d71;
}

h2 {
  font-weight: 300;
  font-size: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #808083;
  line-height: 1.25;
}

hr {
  display: block;
  width: 3.125rem;
  margin: 0 auto 20px;
  border: none;
  border-top: 3px solid #f9be00;
}

/**
 * Header Styles
**/
header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: #fff;
}

header .row {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  alignitems: center;
}

/**
 * Forms
**/
.form-group {
  display: block;
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="number"] {
  width: 100%;
  height: 32px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #808083;
  outline: none;
  font-size: 20px;
  color: #808083;
  z-index: 99;
}

.form-group label {
  position: absolute;
  top: 0;
  color: #808083;
  font-size: 20px;
  font-weight: normal;
  transition: 0.4s all ease;
  z-index: -1;
}

.form-group input[type="text"]:focus ~ label,
.form-group input[type="email"]:focus ~ label,
.form-group input[type="password"]:focus ~ label,
.form-group input[type="text"]:valid ~ label,
.form-group input[type="email"]:valid ~ label,
.form-group input[type="password"]:valid ~ label,
.form-group input[type="number"]:focus ~ label,
.form-group input[type="number"]:valid ~ label {
  top: -17px;
  font-size: 14px;
  color: #f9be00;
}

.form-group .input-bar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 14px;
  width: 0;
  height: 3px;
  background: #f9be00;
  transition: all 0.4s ease;
}

.form-group input[type="text"]:focus ~ .input-bar,
.form-group input[type="text"]:valid ~ .input-bar,
.form-group input[type="email"]:focus ~ .input-bar,
.form-group input[type="email"]:valid ~ .input-bar,
.form-group input[type="password"]:focus ~ .input-bar,
.form-group input[type="password"]:valid ~ .input-bar,
.form-group input[type="number"]:valid ~ .input-bar,
.form-group input[type="number"]:focus ~ .input-bar {
  width: 100%;
}

.form-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
}

.form-row > div {
  flex: 1 1 auto;
}

.form-group--select select {
  height: 30px;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #808083;
  font-size: 20px;
  color: #808083;
}

.form-group--select select:valid {
  border-bottom: 2px solid #f9be00;
}

/* .form-group--select label {
  display: none;
  position: absolute;
  top: -30px;
} */

.form-group--select select:valid ~ label,
.form-group--select select:focus ~ label {
  color: #f9be00;
}

.form-group--checkbox label {
  position: relative;
  margin: 0 0 0 8px;
  z-index: 99;
}

.form-group--checkbox input[type="checkbox"] {
  position: relative;
  width: 22px;
  height: 22px;
}

.form-group--checkbox input[type="checkbox"]:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #808083;
  transition: border 250ms ease;
}

.form-group--checkbox input[type="checkbox"]:checked:after {
  background: white url("./Assets/img/check.svg") center/60% no-repeat;
  border: 2px solid #f9be00;
}

.form-group--checkbox input[type="checkbox"]:checked ~ label {
  color: #f9be00;
}

input[type="submit"] {
  appearance: none;
  border: 2px solid #808083;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  color: #808083;
  transition: all 250ms ease-in-out;
}

input[type="submit"]:hover {
  border: 2px solid #f9be00;
  color: #f9be00;
}

.button {
  display: block;
  margin: 60px auto 0;
  position: relative;
  padding: 20px 30px;
  border: 2px solid #f9be00;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: #f9be00;
  transition: all 250ms ease-in-out;
}

.button:hover {
  border: 2px solid #004d71;
  color: #004d71;
}

/**
 *
 * Card Styles
 *
**/

.card {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 300px);
  margin: 0 auto;
  padding: 30px;
  width: 600px;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 16px 1px;
  transition: all 500ms ease-in-out;
  transform-origin: right;
  transform: translateX(0) scale(1);
}

.card--hidden {
  transform: translateX(-200%) scale(0);
  transition: all 500ms ease-in-out;
  transform-origin: right;
}

.card--hidden-right {
  transform: translateX(200%) scale(0);
  transition: all 500ms ease-in-out;
  transform-origin: right;
}

/**
 *
 * Media Queries
 *
**/
@media screen and (max-width: 620px) {
  .card {
    max-width: 95%;
    left: 2.5%;
  }

  .form-group label {
    position: absolute;
    top: 0;
    color: #808083;
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s all ease;
    z-index: -1;
  }
}

@media screen and (max-width: 460px) {
  header {
    height: auto;
  }

  header .row {
    justify-content: center;
  }

  header .row a img {
    padding: 8px 0 10px !important;
  }

  header .row a:nth-child(2) {
    margin-bottom: 10px !important;
  }
}
